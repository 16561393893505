import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lineColor, darkBaseColor, darkPrimaryColor, primaryLightTextColor, primaryLineColor, darkAccentColor, accentLightTextColor, accentLineColor } from 'views/services/color-scheme';

import { columnWidth } from '../../index.styled';

export const Metrics = styled.div`
  width: ${columnWidth};
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 0.8);
  text-align: right;
  z-index: 0;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -0.5px;
    right: 0;
    bottom: 0;
    border-left: 1px solid transparent;
    pointer-events: none;
  }
  &.category {
    background-color: ${darkPrimaryColor};
    color: ${primaryLightTextColor};
    &::after {
      border-color: ${primaryLineColor};
    }
    &.sum, &.average {
      background-color: ${darkAccentColor};
      color: ${accentLightTextColor};
      &::after {
        border-color: ${accentLineColor};
      }
    }
  }
  &.item {
    background-color: ${baseColor};
    color: ${textColor};
    &::after {
      border-color: ${lineColor};
    }
  }
  &.ingredient {
    background-color: ${darkBaseColor};
    color: ${textColor};
    &::after {
      border-color: ${lineColor};
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    flex: 1;
    padding: 8px 16px;
    white-space: nowrap;
  }
`;
