import { MenuCategory } from 'models/entities/menu-category';
import { DishCategory } from 'models/entities/dish-category';
import { DishItemAnalysis } from 'models/entities/dish-item-analysis';
import { OrderedMenuItemDatasetCollection } from 'models/entities/ordered-menu-item';

import { Column } from '../../models/column';

type Data = {
  columns: Column[];
  menuCategory: MenuCategory;
  dishCategory: DishCategory;
};

type DataToInit = {
  analysis?: DishItemAnalysis[];
  columns?: Column[];
};

type DataToLoad = {
  datasets: OrderedMenuItemDatasetCollection;
};

class Model {

  readonly columns: Column[];
  readonly menuCategory: MenuCategory;
  readonly dishCategory: DishCategory;
  readonly analysis: DishItemAnalysis[];

  constructor(data: Data) {
    this.columns = data.columns;
    this.menuCategory = data.menuCategory;
    this.dishCategory = data.dishCategory;
    this.analysis = [];
  }

  init(data: DataToInit = {}): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  load(data: DataToLoad): this {
    const analysis = this.analysis.map(it => it.load(data.datasets));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, analysis });
  }

}

export { Model };