import { Business, BusinessCollection } from 'models/entities/business';
import { DishItemAnalysisCollection } from 'models/entities/dish-item-analysis';
import { OrderedMenuItemDatasetCollection, OrderedMenuItemDatasetCollectionConditions } from 'models/entities/ordered-menu-item';
import { DailySalesDatasetCollection, DailySalesDatasetCollectionConditions } from 'models/entities/daily-sales';
import { SalesAnalysis } from 'models/entities/sales-analysis';
import { Period } from 'models/value-objects/period';

import { Column } from './models/column';
import { ReadBusinessGql } from '../models/read-business-gql';

type DataToInit = {
  business: Business;
  businesses: BusinessCollection;
};

type DataToSearch = {
  period: Period;
};

class Model {

  readonly business?: Business;
  readonly businesses?: BusinessCollection;
  readonly analyses?: DishItemAnalysisCollection[];
  readonly disabledAction: { onChat: boolean };
  readonly conditions: OrderedMenuItemDatasetCollectionConditions;
  readonly columns: Column[];
  readonly datasets?: OrderedMenuItemDatasetCollection;
  readonly dailySalesConditions: DailySalesDatasetCollectionConditions;
  readonly dailySales?: DailySalesDatasetCollection;
  readonly salesAnalysis: SalesAnalysis;
  readonly searching: boolean;

  constructor() {
    this.disabledAction = { onChat: true };
    this.conditions = new OrderedMenuItemDatasetCollectionConditions();
    this.columns = Column.collectFrom(this.conditions.period);
    this.dailySalesConditions = new DailySalesDatasetCollectionConditions();
    this.salesAnalysis = new SalesAnalysis({ period: this.dailySalesConditions.period });
    this.searching = false;
  }

  init(data: DataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  async readBusiness(code: string): Promise<this> {
    const gql = await new ReadBusinessGql().fetch({ code });
    const { business, businesses } = gql;
    if (!business) throw new Error('business is undefined');
    const conditions = new OrderedMenuItemDatasetCollectionConditions({ timezone: this.getTimezone(business) });
    const columns = Column.collectFrom(conditions.period);
    const dailySalesConditions = new DailySalesDatasetCollectionConditions({ timezone: this.getTimezone(business) });
    const salesAnalysis = new SalesAnalysis({ period: dailySalesConditions.period });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, business, businesses, conditions, columns, dailySalesConditions, salesAnalysis });
  }

  async read(): Promise<this> {
    const [analyses, datasets, dailySales] = await Promise.all([this.getDishItemAnalysisCollections(), this.getOrderedMenuItemDatasetCollection(), this.getDailySalesDatasetCollection()]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, analyses, datasets, dailySales });
  }

  startSearching(): this {
    const searching = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, searching });
  }

  async search(data: DataToSearch): Promise<this> {
    const { period } = data;
    const conditions = this.conditions.apply({ period });
    const columns = Column.collectFrom(conditions.period);
    const dailySalesConditions = this.dailySalesConditions.apply({ period });
    const salesAnalysis = new SalesAnalysis({ period: dailySalesConditions.period });
    const [datasets, dailySales] = await Promise.all([this.getOrderedMenuItemDatasetCollection(conditions), this.getDailySalesDatasetCollection(dailySalesConditions)]);
    const searching = false;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, conditions, columns, datasets, dailySalesConditions, salesAnalysis, dailySales, searching });
  }

  private async getDishItemAnalysisCollections(): Promise<DishItemAnalysisCollection[]> {
    if (!this.business) throw new Error('business is undefined');
    return await Promise.all(this.business.menuCategories.documents.map(it => DishItemAnalysisCollection.read(it.id!)));
  }

  private async getOrderedMenuItemDatasetCollection(conditions?: OrderedMenuItemDatasetCollectionConditions): Promise<OrderedMenuItemDatasetCollection> {
    if (!this.business) throw new Error('business is undefined');
    return await OrderedMenuItemDatasetCollection.read(this.business.code, conditions ?? this.conditions);
  }

  private async getDailySalesDatasetCollection(dailySalesConditions?: DailySalesDatasetCollectionConditions): Promise<DailySalesDatasetCollection> {
    if (!this.business) throw new Error('business is undefined');
    return await DailySalesDatasetCollection.read(this.business.code, dailySalesConditions ?? this.dailySalesConditions);
  }

  private getTimezone(business: Business): string {
    switch (business.code) {
      case 'miku-vancouver': return 'America/Vancouver';
      case 'minami-vancouver': return 'America/Vancouver';
      case 'aburi-market-w-vancouver': return 'America/Vancouver';
      case 'kepo-bagels-vancouver': return 'America/Vancouver';
      case 'miku-toronto': return 'America/Toronto';
      case 'minami-toronto': return 'America/Toronto';
      case 'tora-toronto': return 'America/Toronto';
      case 'tora-futako-tamagawa': return 'Asia/Tokyo';
      case 'tora-jiyugaoka': return 'Asia/Tokyo';
      default: return 'America/Vancouver';
    }
  }

}

export { Model };
export type { DataToSearch };
