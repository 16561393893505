import styled from 'styled-components';

import { baseFontSize } from 'views/services/font-scheme';

import { Columns, labelWidth, columnWidth } from '../../index.styled';

export const Row = styled.div<Columns>`
  width: calc(${labelWidth} + ${p => p.columns} * ${columnWidth});
  &.category {
    z-index: 2;
    position: sticky;
    top: calc(${baseFontSize} * 0.8 + 8px * 2);
  }
  display: flex;
  justify-content: start;
`;
