import styled, { css } from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, lightTextColor, lineColor, darkBaseColor, darkPrimaryColor, primaryTextColor, primaryLightTextColor, primaryLineColor, darkSecondaryColor, secondaryTextColor, secondaryLightTextColor, secondaryLineColor } from 'views/services/color-scheme';

import { labelWidth } from '../../index.styled';

const headingWidth = '240px';

const ingredient = (i: number) => css`
  &.ingredient-${i} {
    background-color: ${darkBaseColor};
    &::after {
      border-color: ${lineColor};
    }
    & > h4 {
      color: ${textColor};
      flex: 0 0 calc(${headingWidth} - 16px * ${i});
      width: calc(${headingWidth} - 16px * ${i});
      padding-left: calc(16px * ${i});
    }
    & > div {
      color: ${lightTextColor};
    }
  }
`;

export const Label = styled.div`
  flex: 0 0 ${labelWidth};
  z-index: 1;
  position: sticky;
  left: 0;
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 0.8);
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: end;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: -0.5px;
    bottom: 0;
    border-right: 1px solid transparent;
    pointer-events: none;
  }
  & > h2, & > h3, & > h4 {
    flex: 0 0 ${headingWidth};
    width: ${headingWidth};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > div {
    white-space: nowrap;
  }
  &.category {
    background-color: ${darkPrimaryColor};
    &::after {
      border-color: ${primaryLineColor};
    }
    & > h2 {
      color: ${primaryTextColor};
    }
    & > div {
      color: ${primaryLightTextColor};
    }
  }
  &.item {
    background-color: ${darkSecondaryColor};
    &::after {
      border-color: ${secondaryLineColor};
    }
    & > h3 {
      color: ${secondaryTextColor};
    }
    & > div {
      color: ${secondaryLightTextColor};
    }
  }
  ${[...Array(8)].map((_, i) => ingredient(i + 1))}
`;
