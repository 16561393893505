import React, { FC, useState, useEffect } from 'react';

import { MenuCategory } from 'models/entities/menu-category';
import { DishCategory } from 'models/entities/dish-category';
import { DishItemAnalysisCollection } from 'models/entities/dish-item-analysis';
import { OrderedMenuItemDatasetCollection } from 'models/entities/ordered-menu-item';
import { Column } from '../../models/column';
import { Model } from './index.model';

import { showUnit, showNumber } from 'views/services/helpers';
import { Row } from '../row';
import { Label } from '../label';
import { Metrics } from '../metrics';
import { Weekly } from '../weekly';
import { Ingredient } from '../ingredient';
import { Root, Item } from './index.styled';

interface Props {
  columns: Column[];
  menuCategory: MenuCategory;
  dishCategory: DishCategory;
  analyses?: DishItemAnalysisCollection[];
  datasets?: OrderedMenuItemDatasetCollection;
}

const Category: FC<Props> = ({ columns, menuCategory, dishCategory, analyses, datasets }) => {

  const [model, setModel] = useState(new Model({ columns, menuCategory, dishCategory }));

  function init() {
    const analysis = analyses?.find(it => it.menuCategoryId === menuCategory.id)?.documents.filter(it => it.category.id === dishCategory.id) ?? [];
    setModel(model.init({ columns, analysis }));
  }

  function load() {
    if (!datasets) return;
    const timer = setTimeout(() => setModel(model => model.load({ datasets })), 0);
    return () => clearTimeout(timer);
  }

  useEffect(init, [analyses, columns]);
  useEffect(load, [datasets]);

  return (
    <Root>
      {!!model.analysis.length && (
        <>
          <Row columns={model.columns.length} className="category">
            <Label className="category">
              <h2>{`${menuCategory.original.name} : ${dishCategory.original.name}`}</h2>
              <div>Unit</div>
            </Label>
            {model.columns.map((column, i) => (
              <Metrics key={i} className={`${column.type} category`}>
                <div>Quantity</div>
                <div>Subtotal</div>
                <div>Total</div>
              </Metrics>
            ))}
          </Row>
          {model.analysis.map(dishItem => (
            <Item key={dishItem.id}>
              <Row columns={model.columns.length}>
                <Label className="item">
                  <h3>{dishItem.name}</h3>
                  <div>{showUnit(dishItem.unit, dishItem.ingredientItem?.unitSymbol)}</div>
                </Label>
                {dishItem.statistics?.weeklies.map((weekly, i) => (
                  <Weekly key={i}>
                    {weekly.dailies.map((daily, j) => (
                      <Metrics key={j} className="item date">
                        <div>{showNumber(daily.quantity)}</div>
                        <div>{showUnit(daily.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                        <div>{showUnit(daily.total, dishItem.ingredientItem?.unitSymbol)}</div>
                      </Metrics>
                    ))}
                    <Metrics className="item total">
                      <div>{showNumber(weekly.sum.quantity)}</div>
                      <div>{showUnit(weekly.sum.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                      <div>{showUnit(weekly.sum.total, dishItem.ingredientItem?.unitSymbol)}</div>
                    </Metrics>
                    <Metrics className="item average">
                      <div>{showNumber(weekly.average.quantity)}</div>
                      <div>{showUnit(weekly.average.subtotal, dishItem.ingredientItem?.unitSymbol)}</div>
                      <div>{showUnit(weekly.average.total, dishItem.ingredientItem?.unitSymbol)}</div>
                    </Metrics>
                  </Weekly>
                ))}
              </Row>
              {dishItem.ingredientItem?.ingredients.documents.map(ingredient => (
                <Ingredient key={ingredient.id} ingredient={ingredient} index={1} columns={model.columns.length} unitSymbol={dishItem.ingredientItem?.unitSymbol}>
                  {ingredient.dishItem.ingredientItem?.ingredients.documents.map(ingredient2 => (
                    <Ingredient key={ingredient2.id} ingredient={ingredient2} index={2} columns={model.columns.length} unitSymbol={dishItem.ingredientItem?.unitSymbol}>
                      {ingredient2.dishItem.ingredientItem?.ingredients.documents.map(ingredient3 => (
                        <Ingredient key={ingredient3.id} ingredient={ingredient3} index={3} columns={model.columns.length} unitSymbol={dishItem.ingredientItem?.unitSymbol}>
                          {ingredient3.dishItem.ingredientItem?.ingredients.documents.map(ingredient4 => (
                            <Ingredient key={ingredient4.id} ingredient={ingredient4} index={4} columns={model.columns.length} unitSymbol={dishItem.ingredientItem?.unitSymbol}>
                              {ingredient4.dishItem.ingredientItem?.ingredients.documents.map(ingredient5 => (
                                <Ingredient key={ingredient5.id} ingredient={ingredient5} index={5} columns={model.columns.length} unitSymbol={dishItem.ingredientItem?.unitSymbol}>
                                  {ingredient5.dishItem.ingredientItem?.ingredients.documents.map(ingredient6 => (
                                    <Ingredient key={ingredient6.id} ingredient={ingredient6} index={6} columns={model.columns.length} unitSymbol={dishItem.ingredientItem?.unitSymbol}>
                                      {ingredient6.dishItem.ingredientItem?.ingredients.documents.map(ingredient7 => (
                                        <Ingredient key={ingredient7.id} ingredient={ingredient7} index={7} columns={model.columns.length} unitSymbol={dishItem.ingredientItem?.unitSymbol}>
                                          {ingredient7.dishItem.ingredientItem?.ingredients.documents.map(ingredient8 => (
                                            <Ingredient key={ingredient8.id} ingredient={ingredient8} index={8} columns={model.columns.length} unitSymbol={dishItem.ingredientItem?.unitSymbol} />
                                          ))}
                                        </Ingredient>
                                      ))}
                                    </Ingredient>
                                  ))}
                                </Ingredient>
                              ))}
                            </Ingredient>
                          ))}
                        </Ingredient>
                      ))}
                    </Ingredient>
                  ))}
                </Ingredient>
              ))}
            </Item>
          ))}
        </>
      )}
    </Root>
  );

};

export { Category };
