import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { darkBaseColor, darkPrimaryColor, lightPrimaryColor, primaryTextColor, primaryLineColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

export interface Columns {
  columns: number;
}

export const labelWidth = '360px';
export const columnWidth = '280px';

export const Root = styled.div`
`;

export const Content = styled.div`
  border-top: 1px solid ${darkPrimaryColor};
`;

export const Frame = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - ${headerHeight} - 1px);
  overflow: scroll;
  background-color: ${darkBaseColor};
`;

export const HeaderRow = styled.div<Columns>`
  z-index: 10;
  position: sticky;
  top: 0;
  width: calc(${labelWidth} + ${p => p.columns} * ${columnWidth});
  display: flex;
  justify-content: start;
`;

export const Tools = styled.div`
  flex: 0 0 ${labelWidth};
  z-index: 9;
  position: sticky;
  left: 0;
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 0.8);
  background-color: ${lightPrimaryColor};
  color: ${primaryTextColor};
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: -0.5px;
    bottom: 0;
    border-right: 1px solid ${primaryLineColor};
    pointer-events: none;
  }
`;

export const Series = styled.div`
  position: relative;
  z-index: 8;
`;

export const Department = styled.div`
  position: relative;
`;
