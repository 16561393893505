import React, { FC, PropsWithChildren } from 'react';

import { IngredientAnalysis } from 'models/entities/ingredient-analysis';

import { showUnit, showNumber } from 'views/services/helpers';
import { Row } from '../row';
import { Label } from '../label';
import { Metrics } from '../metrics';
import { Weekly } from '../weekly';
import { Root } from './index.styled';

interface Props extends PropsWithChildren {
  ingredient: IngredientAnalysis;
  index: number;
  columns: number;
  unitSymbol?: string;
}

const Ingredient: FC<Props> = ({ children, ingredient, index, columns, unitSymbol }) => {

  return (
    <Root>
      <Row columns={columns}>
        <Label className={`ingredient-${index}`}>
          <h4>{ingredient.dishItem.name}</h4>
          <div>{showUnit(ingredient.usage, unitSymbol)}</div>
        </Label>
        {ingredient.statistics?.weeklies.map((weekly, i) => (
          <Weekly key={i}>
            {weekly.dailies.map((daily, j) => (
              <Metrics key={j} className="ingredient date">
                <div>{showNumber(daily.quantity)}</div>
                <div>{showUnit(daily.subtotal, unitSymbol)}</div>
                <div></div>
              </Metrics>
            ))}
            <Metrics className="ingredient total">
              <div>{showNumber(weekly.sum.quantity)}</div>
              <div>{showUnit(weekly.sum.subtotal, unitSymbol)}</div>
              <div></div>
            </Metrics>
            <Metrics className="ingredient average">
              <div>{showNumber(weekly.average.quantity)}</div>
              <div>{showUnit(weekly.average.subtotal, unitSymbol)}</div>
              <div></div>
            </Metrics>
          </Weekly>
        ))}
      </Row>
      {children}
    </Root>
  );

};

export { Ingredient };